import React from 'react';
import '../styles/global.scss';
import Layout from '../components/layout';
import CCLLeadspace from '../components/cclLeadspace';
import GovernmentStats from './partials/governmentStats';
import CardSlider from '../components/cardSlider';
import JourneyForm from '../components/journeyForm';
import CCLButton from '../components/cclButton';
import CCLExplore from '../components/cclExplore';
import '../styles/global.scss';
import LeadImage from '../images/government-leadspace.jpg';
import SEO from '../components/seo';
import Story1 from '../images/stories/government1.jpg';

let cards = [
    {
        header: 'Explore our stories of impact & our latest thinking',
        subheader: '',
        cards: [
            {
                title: 'Headline: 5 Myths of Government Leadership',
                link: 'https://www.ccl.org/articles/leading-effectively-articles/the-5-myths-of-government-leadership/',
                label: 'Read Article'
            },
            {
                title: 'COVID 19: Turning Crisis Into Opportunity',
                link: 'https://www.ccl.org/articles/white-papers/turning-crisis-into-opportunity/',
                label: 'Read our POV'
            },
            {
                title: 'HR Pipeline: Leading in the Government is Different. Or is it?',
                link: 'https://www.ccl.org/articles/leading-effectively-articles/hr-pipeline-leading-in-government-is-different-or-is-it/',
                label: 'Read Article'
            },
            {
                title: 'How Government Workers Can Collaborate Across Boundaries',
                link: 'https://www.ccl.org/articles/leading-effectively-articles/government-all-talk-and-no-action/',
                label: 'Read Article'
            },
            {
                title: 'Disruptive Trends Report: Talent Reimagined 2020',
                link: 'https://www.ccl.org/articles/research-reports/talent-reimagined-disruptive-trends-2020/',
                label: 'Read Trends Report'
            },
            {
                title: 'Boundary Spanning as Battle Rhythm',
                link: 'https://www.ccl.org/articles/client-successes/boundary-spanning-as-battle-rhythm/',
                label: 'View Client Success'
            },
        ]
    }
];

const Government = () => (
    <Layout>
        <SEO
            title="Government Leadership | CCL"
            description="In an ever changing world, one constant fact remains: great leadership can turn challenge into opportunity. See how CCL has helped government agencies and contractors."
            meta={{
                imgAlt: 'Government leadership executive looking to the future. '
            }}
            noFollowIndexed
        />
        <CCLLeadspace image={LeadImage} copy="EVOLVE.  ADAPT.  LEAD THROUGH DISRUPTION." backgroundAlginRight alt="Government leadership executive looking to the future" />
        <div className="black-text-box">
            <h2>In today’s world, the only thing that remains the same is change – and change is more rapid and complex than ever before. How can government organizations navigate the crisis of the current moment, while remaining focused on the mission-driven objectives critical to thriving in the "new normal"? Agile and innovative leadership is no longer an asset, but an imperative.</h2>
            <h2>At the Center for Creative Leadership, we’ve been delivering leadership development programs for the government sector for decades. With this proven experience we know we can guide you and your government organization through this time of disruption – and the transformed future that lies ahead.</h2>
            <h2>We’re a pre-qualified vendor on the <a href="https://www.ccl.org/open-enrollment-programs/gsa-training-schedule/" target="_blank" rel="noopener noreferrer">GSA Training Schedule</a> and can work directly with your agency, or partner with others who share our passion for leadership development.</h2>
        </div>
        <GovernmentStats />
        <div className="stories">
            <span>
                <CCLExplore
                    className='story'
                    label='FEATURED WEBINAR'
                    description={<>Government Leadership After Crisis: Resetting Your Mindset and Expanding Your Tool Set<br /><br />Date: Tuesday, August 4th<br />Time: 1:00 PM – 2:00 PM EDT</>}
                    image={Story1}
                    link='https://cclwebinars.webvent.tv/webinar/3840'
                    cta='register now'
                    btnClass='primary'
                    isFinancialCard />
            </span>
            <div className="description">
                <div className="wrapper">
                    <div>Attend this webinar and learn:</div>
                    <ul>
                        <li>A research-based model to describe and apply the 3 critical tasks of leadership.</li>
                        <li>A post-crisis mindset based on military lessons and organizational experience drawn from CCL’s guidebook, Crisis Leadership.</li>
                        <li>A new tool set for government leaders to leverage for planning beyond crisis and leading in the "new normal."</li>
                    </ul>
                </div>
            </div>
        </div>

        <div className="testimonial-section">
            <div className="white-bg-quote-text">
                <h2 className="text-wrapper">
                    “This is not your typical virtual leadership program. It guides you through introspective exercises that gives you tangible skills you can apply to real life situations.”
                </h2>
            </div>
            <div className="shape-divider">
                <div className="shape-left"></div>
                <div className="shape-right"></div>
            </div>
            <div className="name">—U.S. Program Participant, Department of Defense</div>
        </div>
        <CardSlider {...cards} />
        <JourneyForm formName="lns-government" interestCode="FORMVERTCONTACTGOVTLNS" description="The world has changed. Your industry is transforming. Your leaders are stepping up. Let’s keep the momentum going. Contact CCL to talk more about your needs." btnTxt="REQUEST CONSULTATION" />
    </Layout>
);

export default Government;
